*::before, *::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    background: linear-gradient(to right, #36393e, #1e2124)
}

.calculator-grid {

    display: grid;
    margin-top: 2rem;
    grid-template-columns: repeat(4, 6rem);
    grid-template-rows: minmax(7rem, auto) repeat(5, 6rem);
    justify-content: center;
    
}

.calculator-grid > button {

    cursor: pointer;
    font-size: 2rem;
    border: 1px solid white;
    outline: none;
    background-color: rgba(255, 255, 255, .75)

}

.calculator-grid > button:hover,
.calculator-grid > button:focus {

    background-color: rgba(255, 255, 255, .9)

    
}

.span-two {
    grid-column: span 2;
}

.output {
    grid-column: 1 / -1;
    background-color: rgba(0, 0, 0, .75);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    padding: .75rem;
    word-wrap: break-word;
    word-break: break-all;
}

.output .previous-operand {
    color: rgba(255, 255, 255, .75);
    font-size: 1.5rem
}

.output .current-operand {
    color: white;
    font-size: 2.5rem
}

.footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height:50px;
    background:#ccc;
    text-align: center;
    background-color: #1e2124;
    color: white;
}

